.drawing-board-container {
  position: relative;
  font-family: "PingFang SC", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif, "localant";
  font-size: 12px;
  color: #314659;
}
.drawing-board-container .ant-layout {
  height: 100%;
}
.drawing-board-container .ant-layout-content {
  height: 100%;
  width: 100%;
}
.drawing-board-container .ant-layout-header {
  padding: 0 0 0 0;
  height: 55px;
  border-radius: 3px 3px 0px 0px;
}
