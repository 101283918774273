.ant-layout-sider-children .drawing-board-toolbar-container {
  width: 55px;
  height: 100%;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 0;
  padding-left: 0;
  border-radius: 0px 0px 0px 0px;
  overflow: auto;
}
.ant-layout-sider-children .drawing-board-toolbar-icon {
  width: 100%;
  cursor: pointer;
  margin: 7px 0px;
  height: 50px;
  flex-shrink: 0;
}
.ant-layout-sider-children .drawing-board-toolbar-mobile-icon {
  height: 40px;
  width: 45px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16);
  border-radius: 0 12px 12px 0;
  position: relative;
}
.ant-layout-sider-children .drawing-board-toolbar-mobile-icon:hover {
  background-color: white;
}
.ant-layout-sider-children .drawing-board-toolbar-mobile-icon svg {
  margin-left: 5px;
}
.ant-layout-sider-children .drawing-board-toolbar-mobile-container {
  box-shadow: none;
  overflow: visible;
  width: 40px;
  height: auto;
  background: rgba(0, 0, 0, 0);
}
.drawing-board-toolbar-container {
  display: flex;
  height: 55px;
  background: #ffffff;
  box-shadow: -3px 0px 13px 0px rgba(0, 0, 0, 0.08);
  border-radius: 3px 3px 0px 0px;
  padding-left: 20px;
  padding-right: 150px;
  position: relative;
  z-index: 1;
}
.drawing-board-toolbar-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  cursor: pointer;
  margin: 0 7px;
}
.drawing-board-toolbar-icon svg {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
  fill: #1890ff;
}
.drawing-board-toolbar-icon:hover {
  background: #eeeeee;
}
.drawing-board-toolbar-iconLabel {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  user-select: none;
}
.drawing-board-toolbar-activeIcon {
  background: #eeeeee;
}
